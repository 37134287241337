import { Container } from "react-bootstrap";
import "./style.css";
import Logo from "../../Assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import { IoMenu } from "react-icons/io5";


const Navbar = () => {
    const { pathname } = useLocation();
    const [navbar, setNavbar] = useState(false);
    const [toggleNav, setToggleNav] = useState(false)

    window.addEventListener("scroll", () => {
        // console.log(window.scrollY, window.innerHeight)
        if (window.scrollY > window.innerHeight - 200) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    })

    return (
        <>
            <header className={navbar ? "srcoll-header" : ""}>
                <Container className="nav-conatiner">
                    <Link to={'/'} className="logo-div">
                        <img src={Logo} alt="Logo" />
                    </Link>
                    <ul>
                        <li>
                            <Link to={'/'} className={`nav-links ${pathname === '/' ? 'active-links' : ''}`}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to={'/services'} className={`nav-links ${pathname === '/services' ? 'active-links' : ''}`}>
                                Services
                            </Link>
                        </li>
                        <li>
                            <Link to={'/reviews'} className={`nav-links ${pathname === '/reviews' ? 'active-links' : ''}`}>
                                Reviews
                            </Link>
                        </li>
                        <li>
                            <Link to={'/aboutUs'} className={`nav-links ${pathname === '/aboutUs' ? 'active-links' : ''}`}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to={'/contactUs'} className={`nav-links ${pathname === '/contactUs' ? 'active-links' : ''}`}>
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                    <span className="menu-icon" htmlFor="menu-input" onClick={e => setToggleNav(true)}>
                        <IoMenu />
                    </span>
                </Container>
            </header>
            {toggleNav && <div className="slider-main">
                <h2>
                    <img src={Logo} alt="Logo" />
                    <span onClick={e => setToggleNav(false)}>
                        <ImCross />
                    </span>
                </h2>
                <ul>
                    <li>
                        <Link to={'/'} className={`nav-links res-nav-links ${pathname === '/' ? 'active-links' : ''}`}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to={'/services'} className={`nav-links res-nav-links ${pathname === '/services' ? 'active-links' : ''}`}>
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to={'/reviews'} className={`nav-links res-nav-links ${pathname === '/reviews' ? 'active-links' : ''}`}>
                            Reviews
                        </Link>
                    </li>
                    <li>
                        <Link to={'/aboutUs'} className={`nav-links res-nav-links ${pathname === '/aboutUs' ? 'active-links' : ''}`}>
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to={'/contactUs'} className={`nav-links res-nav-links ${pathname === '/contactUs' ? 'active-links' : ''}`}>
                            Contact Us
                        </Link>
                    </li>
                </ul>
            </div>}
        </>
    )
}

export default Navbar;