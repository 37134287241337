import React from 'react'
import "./style.css";
import { SiGooglemessages } from "react-icons/si";
import { FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function StickyIcons() {
    return (
        <>
            <input className='hidden-input' type="checkbox" id='chk' name='checkbox' />
            <label htmlFor="chk" className='icon-check'>
                <SiGooglemessages />
            </label>
            <Link to={'https://wa.me/13868469348'} target='_blank' className='icons-div icons-div-1'>
                <FaWhatsapp className="nav-icons" />
            </Link>
            <Link to={'https://x.com/ArtistryModel?t=5LbTgHQD_9SU7DeSiQVrXA&s=09'} target='_blank' className='icons-div icons-div-2'>
                <FaXTwitter className="nav-icons" />
            </Link>
        </>

    )
}

export default StickyIcons;