import "./style.css";
import Footer from "../../Components/Footer/index.jsx";
import Counter from "../../Section/Counter/index.jsx";
import { Container } from "react-bootstrap";
import FeedbackForm from "../../Section/FeedbackForm/index.jsx";

const AboutUs = () => {
    return (
        <>
            <div className="service-hero about-hero">
                <Container className="about-us-container">
                    <h1>
                        Who we are?
                    </h1>
                    <p>
                        At the core of our identity lies an unwavering commitment to exceed the ordinary.
                        We don't settle for the minimum; instead, we relentlessly pursue perfection.
                        Your satisfaction isn't just a target; it's our guiding principle. Every endeavor
                        is a testament to our steadfast dedication to meeting and surpassing your expectations.
                        We immerse ourselves in the digital realm, embodying the spirit of a born- company.
                        Understanding our clients' unique and evolving needs is embedded in our DNA, and we
                        tirelessly strive to fulfill those needs to the utmost.
                    </p>
                    <p>
                        Digital innovation runs deep in our veins, defining us as a company born in
                        the digital era. We don't just work hard; we live and breathe digital, symbolizing
                        our profound connection to the ever-changing landscape of the online world. Our pride
                        stems from the exceptional quality of our work, and seeing your business thrive is
                        the ultimate validation of our boundless creativity. Every project is an opportunity
                        to elevate our craft, pushing the boundaries of what's possible to unlock new levels
                        of ingenuity and originality.
                    </p>
                    <p>
                        Our commitment to excellence transcends mere words; it's a driving force that
                        ignites our passion for what we do. We take immense pride in our work, viewing each
                        client's success as a reflection of our unwavering dedication to delivering unmatched
                        results. Your business flourishing isn't just a goal; it propels us to new heights
                        of creativity, inspiring us to continually refine and redefine the standards of
                        excellence in our industry. We're not just a service provider; we're your devoted
                        partner in achieving and surpassing your digital aspirations.
                    </p>
                </Container>
            </div >
            <Counter />
            <FeedbackForm />
            <Footer />
        </>
    )
}


export default AboutUs;