// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
// my styles file
import './style.css';
import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import Quote1 from "../../Assets/Testimonials/quote1.png";
import Quote2 from "../../Assets/Testimonials/quote2.png";
import MaleIcon from "../../Assets/Testimonials/male-icon.png";
import FemaleIcon from "../../Assets/Testimonials/female-icon.png";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../../index.js'; // Make sure this path is correct

const listenToFeedbackUpdates = (callback) => {
    // Get a reference to the feedback collection
    const feedbackCollection = collection(db, 'feedback');

    // Create a query to filter documents where 'approved' is true
    const feedbackQuery = query(feedbackCollection, where('approved', '==', true));

    // Set up a real-time listener
    const unsubscribe = onSnapshot(feedbackQuery, (snapshot) => {
        // Process the changes in the collection
        const feedbackList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));

        // Call the callback function with the updated list
        callback(feedbackList);
    }, (error) => {
        console.error('Error fetching feedback: ', error);
    });

    // Return the unsubscribe function so you can stop listening when needed
    return unsubscribe;
};

const TestimonialSlide = ({ icon, name, email, body }) => (
    <>
        <div className='test-icon-div'>
            <img src={icon} alt={`${name}'s icon`} />
        </div>
        <img src={Quote1} className='quote-img quote1-img' alt="quote1-img" />
        <img src={Quote2} className='quote-img quote2-img' alt="quote2-img" />
        <div className='details-div'>
            <p>
                {body}
            </p>
            <h2>{name}</h2>
        </div>
    </>
);



const Testimonials = ({ setShowLoader }) => {
    const [feedback, setFeedback] = useState([]);
    useEffect(() => {
        setShowLoader(true);
        // Set up the listener
        const unsubscribe = listenToFeedbackUpdates((updatedFeedback) => {
            setFeedback(updatedFeedback);
            setShowLoader(false);
        });

        // Clean up the listener on component unmount
        return () => unsubscribe();
    }, []);

    // State to hold Swiper instance
    const [swiperInstance, setSwiperInstance] = useState(null);
    return (
        <div className="testimonials-main">
            <Container className="testimonials-container">
                <h1 data-aos="fade-right" data-aos-duration={600}>Clients Feedback</h1>
                <div className="testimonials-all-div">
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 100,
                            modifier: 2,
                            slideShadows: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        loop={true}
                        modules={[EffectCoverflow, Pagination]}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                        {
                            feedback.map(v => {
                                return (
                                    <SwiperSlide>
                                        <TestimonialSlide
                                            icon={v.gender === "male" ? MaleIcon : FemaleIcon}
                                            name={v.name}
                                            body={v.message}
                                            email={v.email}
                                        />
                                    </SwiperSlide>
                                )
                            }, [])
                        }
                        <button
                            className='previousButton'
                            onClick={() => {
                                if (swiperInstance) {
                                    swiperInstance.slidePrev();
                                }
                            }}
                        >
                            <IoIosArrowBack />
                        </button>
                        <button
                            className='nextButton'
                            onClick={() => {
                                if (swiperInstance) {
                                    swiperInstance.slideNext();
                                }
                            }}
                        >
                            <IoIosArrowForward />
                        </button>
                    </Swiper>
                </div>
            </Container>
        </div>
    )
}

export default Testimonials;



