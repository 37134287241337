import Hero from "../../Components/Hero/index.jsx";
import Services from "../../Section/Services/index.jsx";
import Testimonials from "../../Section/Testimonials/index.jsx";
import Footer from "../../Components/Footer/index.jsx";
import Counter from "../../Section/Counter/index.jsx";
import FeedbackForm from "../../Section/FeedbackForm/index.jsx";

const Home = ({ setShowLoader }) => {
    return (
        <>
            <Hero />
            <Services />
            <Counter />
            <Testimonials setShowLoader={setShowLoader} />
            <FeedbackForm />
            <Footer />
        </>
    )
}


export default Home;