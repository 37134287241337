import "./style.css";
import Footer from "../../Components/Footer/index.jsx";
import Counter from "../../Section/Counter/index.jsx";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OpenSea from "../../Assets/Websites/open-sea.png";
import ShahidAndSons from "../../Assets/Websites/ShahidAndSons.png";
import SMIT1 from "../../Assets/Websites/Smit-devathon-2.png";
import SMIT2 from "../../Assets/Websites/Smit-devathon-3.png";
import Pokemon from "../../Assets/Websites/pokemon.png";
import TakeExamHelp from "../../Assets/Websites/TakeExamHelp.png";
import NexEraTrends from "../../Assets/Websites/NexEraTrends.png";
import SvgComponent from "../../Components/HeadingSvg/index.jsx";


const WebDevelopement = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="service-hero">
                <Container className="service-hero-container website-hero-container">
                    <div className="hero-text-div">
                        <h1>Holistic Website Development</h1>
                        <p>
                            Website development encompasses the creation and maintenance
                            of websites, combining technical skills and creative design.
                            It involves coding, user interface design, and functionality
                            to build responsive and engaging online experiences. Developers
                            work on both the front-end (what users see) and back-end
                            (server-side logic) to ensure seamless performance and
                            accessibility. Effective website development enhances user
                            interaction, optimizes performance, and supports business
                            goals by creating a professional and functional online presence.
                        </p>
                        <button
                            className="contact-btn"
                            onClick={e => navigate('/contactUs')}
                        >
                            Launch Your Site Now
                        </button>
                    </div>
                </Container>
            </div >
            <div className="main-img-div">
                <h1>
                    <p data-aos="fade-right" data-aos-duration={600} >
                        Website
                    </p>
                    <SvgComponent />
                </h1>
                <Container className="img-container">
                    <Link to={'https://www.takexamhelp.com/'} target="_blank" className="website-img-div">
                        <img src={TakeExamHelp} alt="TakeExamHelp" />
                    </Link>
                    <Link to={'https://open-sea-website.netlify.app/'} target="_blank" className="website-img-div">
                        <img src={OpenSea} alt="OpenSea" />
                    </Link>
                    <Link to={'https://www.shahidnsons.co.uk/'} target="_blank" className="website-img-div">
                        <img src={ShahidAndSons} alt="ShahidAndSons" />
                    </Link>
                    <Link to={'https://smit-event-2.vercel.app/'} target="_blank" className="website-img-div">
                        <img src={SMIT1} alt="SMIT1" />
                    </Link>
                    <Link to={'https://pokemon-website-1.netlify.app/'} target="_blank" className="website-img-div">
                        <img src={Pokemon} alt="Pokemon" />
                    </Link>
                    <Link to={'https://smit-event-eta.vercel.app/'} target="_blank" className="website-img-div">
                        <img src={SMIT2} alt="SMIT2" />
                    </Link>
                    <Link to={'https://nexeratrends.com/'} target="_blank" className="website-img-div">
                        <img src={NexEraTrends} alt="NexEraTrends" />
                    </Link>
                </Container>
            </div>
            <Counter />
            <Footer />
        </>
    )
}


export default WebDevelopement;