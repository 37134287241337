import "./style.css";
import Services from "../../Section/Services/index.jsx";
import Footer from "../../Components/Footer/index.jsx";
import Counter from "../../Section/Counter/index.jsx";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Service = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="service-hero">
                <Container className="service-hero-container">
                    <div className="hero-text-div">
                        <h1>Strategic Digital Product Design and Guidance</h1>
                        <p>
                            Our global digital product design agency partners with brands
                            to create exceptional websites, logo, avatars. We specialize in crafting user-friendly designs that
                            are both visually stunning and highly functional. Let our expert solutions
                            drive your business growth.
                        </p>
                        <button
                            className="contact-btn"
                            onClick={e => navigate('/contactUs')}
                        >
                            Design Your Product with Us Today
                        </button>
                    </div>
                </Container>
            </div >
            <Services />
            <Counter />
            <Footer />
        </>
    )
}


export default Service;