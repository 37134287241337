import { Container } from "react-bootstrap"
import "./style.css"
import { FaTrophy, FaClockRotateLeft, } from "react-icons/fa6";
import { IoPerson } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import CounterAnimation from "../../Components/Counter/index.jsx";

const Counter = () => {
    return (
        <div className="counter-main">
            <Container className="counter-container">
                <div className="counter-div-main">
                    <h1 data-aos="fade-right" data-aos-duration={600} className="counter-hd-1" >We Are</h1>
                    <div className="counter-cards-main" data-aos="flip-left" data-aos-duration={1000}>
                        <div className="counter-card">
                            <div className="text-div">
                                <p>001</p>
                                <div className="counter-icon-div">
                                    <FaTrophy />
                                </div>
                                <h3>Total Awards</h3>
                            </div>
                            <div className="number-div">
                                <h1>
                                    <CounterAnimation
                                        end={2}
                                        duration={5}
                                        enableScrollSpy={true}
                                        useIndianSeparators={true}
                                    />
                                </h1>
                            </div>
                        </div>
                        <div className="counter-card">
                            <div className="text-div">
                                <p>002</p>
                                <div className="counter-icon-div">
                                    <FaClockRotateLeft />
                                </div>
                                <h3>Working From</h3>
                            </div>
                            <div className="number-div">
                                <h1>
                                    <CounterAnimation
                                        end={2022}
                                        duration={5}
                                        enableScrollSpy={true}
                                        seperator=""
                                    />
                                </h1>
                            </div>
                        </div>
                        <div className="counter-card">
                            <div className="text-div">
                                <p>003</p>
                                <div className="counter-icon-div">
                                    <IoPerson />
                                </div>
                                <h3>Team Members</h3>
                            </div>
                            <div className="number-div">
                                <h1>
                                    <CounterAnimation
                                        end={100}
                                        duration={5}
                                        enableScrollSpy={true}
                                        afterText={"+"}
                                        useIndianSeparators={true}
                                    />
                                </h1>
                            </div>
                        </div>
                        <div className="counter-card">
                            <div className="text-div">
                                <p>004</p>
                                <div className="counter-icon-div">
                                    <TiTick />
                                </div>
                                <h3>Successfull Projects</h3>
                            </div>
                            <div className="number-div">
                                <h1>
                                    <CounterAnimation
                                        end={1000}
                                        duration={5}
                                        enableScrollSpy={true}
                                        afterText={"+"}
                                        useIndianSeparators={true}
                                    />
                                </h1>
                            </div>
                        </div>
                    </div>
                    <h1 data-aos="fade-left" className="counter-hd-2" data-aos-duration={1000} >Ayetec Solutions</h1>
                </div>
            </Container>
        </div>
    )
}

export default Counter