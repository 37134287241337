import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from './Route/route';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    AOS.init()
    AOS.refresh();
  })
  return (
    <Routing />
  );
}

export default App;
