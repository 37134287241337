import CountUp from "react-countup";

const CounterAnimation = (props) => {
    const { afterText } = props;
    return (
        <>
            <CountUp {...props} />{afterText ? afterText : ""}
        </>
    )
}

export default CounterAnimation;