import { Container } from "react-bootstrap"
import "./style.css"
import { Link } from "react-router-dom"
import { FaPerson } from "react-icons/fa6";


const Services = () => {
    return (
        <div className="service-main" id="services">
            <Container className="service-container">
                <h1 data-aos="fade-right" data-aos-duration={600} >Our Services</h1>
                <div className="services-div" data-aos="flip-left" data-aos-duration={600}>
                    <Link to={'/graphic'} className="specific-service-div">
                        <div className="service-icon-div">
                            <FaPerson />
                        </div>
                        <div className="service-text-div">
                            <h2>Graphic Designing</h2>
                            <p>
                                Graphic designing is the art of creating visual content to communicate messages
                                effectively through layout, typography, and imagery. It combines creativity
                                and technical skills to produce engaging designs for various media platforms.
                            </p>
                        </div>
                    </Link>
                    <Link to={'/website'} className="specific-service-div">
                        <div className="service-icon-div">
                            <FaPerson />
                        </div>
                        <div className="service-text-div">
                            <h2>Website Development</h2>
                            <p>
                                Web development involves building and maintaining websites by coding and programming
                                to create functional, user-friendly web applications. It includes both front-end
                                design and back-end server management to ensure a seamless online experience.
                            </p>
                        </div>
                    </Link>
                    <Link to={'/seo'} className="specific-service-div">
                        <div className="service-icon-div">
                            <FaPerson />
                        </div>
                        <div className="service-text-div">
                            <h2>Search Engine Optimization</h2>
                            <p>
                                SEO (Search Engine Optimization) is the process of enhancing a website's visibility on
                                search engines by improving its relevance and authority through strategic keywords,
                                content, and technical adjustments. The goal is to increase organic traffic and higher
                                search rankings.
                            </p>
                        </div>
                    </Link>
                </div>
            </Container>
        </div>
    )
}

export default Services