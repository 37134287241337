import './style.css';
import Footer from "../../Components/Footer/index.jsx";
import Counter from "../../Section/Counter/index.jsx";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OpenSea from "../../Assets/Websites/open-sea.png";
import ShahidAndSons from "../../Assets/Websites/ShahidAndSons.png";
import SMIT1 from "../../Assets/Websites/Smit-devathon-2.png";
import SMIT2 from "../../Assets/Websites/Smit-devathon-3.png";
import Pokemon from "../../Assets/Websites/pokemon.png";
import NexEraTrends from "../../Assets/Websites/NexEraTrends.png";
import TakeExamHelp from "../../Assets/Websites/TakeExamHelp.png";
import SvgComponent from '../../Components/HeadingSvg/index.jsx';


const SEO = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="service-hero">
                <Container className="service-hero-container seo-hero-container">
                    <div className="hero-text-div">
                        <h1>Strategic Search Optimization and Insights</h1>
                        <p>
                            Search Engine Optimization (SEO) is the practice of enhancing a website's
                            visibility on search engines like Google. It involves optimizing various
                            elements, such as keywords, content quality, and technical aspects, to
                            improve search rankings and attract organic traffic. SEO strategies aim
                            to increase a website's relevance and authority, leading to higher
                            visibility and better user engagement. By refining these elements,
                            businesses can effectively reach their target audience and boost their
                            online presence.
                        </p>
                        <button
                            className="contact-btn"
                            onClick={e => navigate('/contactUs')}
                        >
                            Boost Your SEO with Us
                        </button>
                    </div>
                </Container>
            </div >
            {/* 2D Art */}
            <div className="main-img-div">
                <h1>
                    <p data-aos="fade-right" data-aos-duration={600} >
                        Website
                    </p>
                    <SvgComponent />
                </h1>
                <Container className="img-container">
                    <Link to={'https://www.takexamhelp.com/'} target="_blank" className="website-img-div">
                        <img src={TakeExamHelp} alt="TakeExamHelp" />
                    </Link>
                    <Link to={'https://open-sea-website.netlify.app/'} target="_blank" className="website-img-div">
                        <img src={OpenSea} alt="OpenSea" />
                    </Link>
                    <Link to={'https://www.shahidnsons.co.uk/'} target="_blank" className="website-img-div">
                        <img src={ShahidAndSons} alt="ShahidAndSons" />
                    </Link>
                    <Link to={'https://smit-event-2.vercel.app/'} target="_blank" className="website-img-div">
                        <img src={SMIT1} alt="SMIT1" />
                    </Link>
                    <Link to={'https://pokemon-website-1.netlify.app/'} target="_blank" className="website-img-div">
                        <img src={Pokemon} alt="Pokemon" />
                    </Link>
                    <Link to={'https://smit-event-eta.vercel.app/'} target="_blank" className="website-img-div">
                        <img src={SMIT2} alt="SMIT2" />
                    </Link>
                    <Link to={'https://nexeratrends.com/'} target="_blank" className="website-img-div">
                        <img src={NexEraTrends} alt="NexEraTrends" />
                    </Link>
                </Container>
            </div>
            <Counter />
            <Footer />
        </>
    )
}


export default SEO;