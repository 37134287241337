import "./style.css"
import Footer from "../../Components/Footer/index.jsx";
import Counter from "../../Section/Counter/index.jsx";
import { Container } from "react-bootstrap";
import FeedbackForm from "../../Section/FeedbackForm/index.jsx";
import Testimonials from "../../Section/Testimonials/index.jsx";
import { useNavigate } from "react-router-dom";

const Reviews = ({ setShowLoader }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="service-hero">
                <Container className="service-hero-container review-hero-container">
                    <div className="hero-text-div">
                        <h1>What Our Clients Are Saying</h1>
                        <p>
                            Discover how our services have transformed our clients' experiences. From exceptional support to outstanding results, read their detailed reviews to see why they trust us and how we've made a meaningful impact on their lives.
                        </p>
                        <button
                            className="contact-btn"
                            onClick={e => navigate('/contactUs')}
                        >
                            Design Your Product with Us Today
                        </button>
                    </div>
                </Container>
            </div >
            <FeedbackForm />
            <Counter />
            <Testimonials setShowLoader={setShowLoader} />
            <Footer />
        </>
    )
}


export default Reviews;