import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

//Pages
import Home from "../Pages/Home/index.jsx"
import Service from "../Pages/Services/index.jsx"
import Reviews from "../Pages/Reviews/index.jsx"
import AboutUs from "../Pages/AboutUs/index.jsx"
import ContactUs from "../Pages/ContactUs/index.jsx"
import GraphicDesigning from "../Pages/GraphicDesigning/index.jsx"
//Component
import ScrollToTop from "../Components/ScrollToTop/index.jsx";
import Navbar from "../Components/Navbar/index.jsx";
import StickyIcons from "../Components/StickyIcons/index.jsx";
import WebDevelopement from "../Pages/WebDevelopement/index.jsx";
import SEO from "../Pages/SEO/index.jsx";
import Loader from "../Components/Loader/index.jsx";
import { useState } from "react";

const Routing = () => {
    const [showLoader, setShowLoader] = useState(false)
    return (
        <Router>
            {showLoader && <Loader />}
            <ScrollToTop />
            <Navbar />
            <Routes>
                <Route path={'/'} element={<Home setShowLoader={setShowLoader} />} />
                <Route path={'/services'} element={<Service setShowLoader={setShowLoader} />} />
                <Route path={'/reviews'} element={<Reviews setShowLoader={setShowLoader} />} />
                <Route path={'/aboutUs'} element={<AboutUs setShowLoader={setShowLoader} />} />
                <Route path={'/contactUs'} element={<ContactUs setShowLoader={setShowLoader} />} />
                <Route path={'/graphic'} element={<GraphicDesigning setShowLoader={setShowLoader} />} />
                <Route path={'/website'} element={<WebDevelopement setShowLoader={setShowLoader} />} />
                <Route path={'/seo'} element={<SEO setShowLoader={setShowLoader} />} />
            </Routes>
            <StickyIcons />
        </Router>
    )
}

export default Routing;