import "./style.css";
import Star from "../../Assets/Hero/stars.png";
import MountainBehind from "../../Assets/Hero/mountains_behind.png";
import MountainFront from "../../Assets/Hero/mountains_front.png";
import Moon from "../../Assets/Hero/moon.png";
import { useState } from "react";

const Hero = () => {
    const [star, setStar] = useState(null);
    const [moon, setMoon] = useState(null);
    const [mountainBehind, setMountainBehind] = useState(null);
    const [mountainFront, setMountainFront] = useState(null);
    const [text, setText] = useState(null);
    const [button, setButton] = useState(null);
    window.addEventListener('scroll', () => {
        let value = window.scrollY;
        setStar(value * 1 + 'px')
        setMoon(value * 0.9 + 'px')
        setMountainBehind(value * 0.5 + 'px')
        setMountainFront(value * 0 + 'px')
        setText(value * 4 + 'px')
        setButton(value * 1.5 + 100 + 'px')

    })
    const animationName = "zoom-in";
    const animationDuration = 600;
    return (
        <div className="hero-sec-main-div">
            <img src={Star} style={{ left: star, }} className="start" id="start" alt="Star" />
            <img data-aos={'zoom-out'} data-aos-duration={animationDuration} src={Moon} style={{ top: moon, }} className="moon" id="moon" alt="Moon" />
            <img data-aos={animationName} data-aos-duration={animationDuration} src={MountainBehind} style={{ top: mountainBehind, }} className="mountain-behind" id="mountain-behind" alt="MountainBehind" />
            <h2 data-aos={animationName} data-aos-delay={animationDuration} data-aos-duration={animationDuration} style={{ marginRight: text, marginTop: moon, }}>Ayetec Solutions</h2>
            <button data-aos={animationName} data-aos-duration={animationDuration} style={{ marginTop: button === '0px' || !button ? '100px' : button, }}>
                <a href={'#services'} className="btn-link">
                    Explore
                </a>
            </button>
            <img data-aos={'zoom-in-up'} data-aos-duration={animationDuration} src={MountainFront} style={{ top: mountainFront, }} className="mountain-front" id="mountain-front" alt="MountainFront" />
        </div>
    )
}

export default Hero;