import "./style.css";
import Footer from "../../Components/Footer/index.jsx";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FeedbackForm from "../../Section/FeedbackForm/index.jsx";

const ContactUs = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="service-hero">
                <Container className="service-hero-container contact-hero-container">
                    <div className="hero-text-div">
                        <h1>Get in Touch</h1>
                        <p>
                            Ready to elevate your digital presence? Whether you're looking for
                            cutting-edge web development, custom avatar design, or immersive
                            VR chat solutions, our team is here to assist. Reach out to us today,
                            and let’s discuss how we can turn your vision into reality with
                            innovative technology and expert solutions
                        </p>
                        <button
                            className="contact-btn"
                            onClick={e => navigate('/contactUs')}
                        >
                            Design Your Product with Us Today
                        </button>
                    </div>
                </Container>
            </div >
            <FeedbackForm />
            <Footer />
        </>
    )
}


export default ContactUs;