import { Container } from "react-bootstrap";
import "./style.css";
import Logo from "../../Assets/logo.png"
import { Link } from "react-router-dom";
import { FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { Tooltip } from "antd";
import FooterVideo from "../../Assets/Hero/stars.mp4";

const Footer = () => {
    return (
        <div className="footer-main-div">
            {/* <div className="custom-shape-divider-bottom-1724258078">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                        className="shape-fill"
                    />
                </svg>
            </div> */}
            <section className="footer-section">
                <div className="background-video-div">
                    <video src={FooterVideo} muted loop autoPlay type="video/mp4"></video>
                </div>
                <Container className="footer-container">
                    <div className="logo-div-footer">
                        <img src={Logo} alt="Logo" />
                        <p>
                            We are dedicated to surpassing ordinary standards and relentlessly pursue perfection to ensure your satisfaction.
                        </p>
                    </div>
                    <div className="quick-links-div">
                        <h2>
                            Quick Links
                        </h2>
                        <ul>
                            <li>
                                <Link to={'/'} className="footer-links">
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to={'/services'} className="footer-links">
                                    Services
                                </Link>
                            </li>
                            <li>
                                <Link to={'/reviews'} className="footer-links">
                                    Reviews
                                </Link>
                            </li>
                            <li>
                                <Link to={'/aboutUs'} className="footer-links">
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link to={'/contactUs'} className="footer-links">
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="quick-links-div quick-links-div-2">
                        <h2>
                            Contact Us
                        </h2>
                        <ul>
                            <li>
                                Email: <Link to={'mailto:info@ayetec.com'} className="footer-links">info@ayetec.com</Link>
                            </li>
                            <li>
                                Phone: <Link to={'tel:+13868469348'} className="footer-links">+1 (386) 846‑9348</Link>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <Tooltip title={'Whats App'}>
                                <Link to={'https://wa.me/13868469348'} target='_blank' className="footer-icons">
                                    <FaWhatsapp />
                                </Link>
                            </Tooltip>
                            <Tooltip title={'Twitter'}>
                                <Link to={'https://x.com/ArtistryModel?t=5LbTgHQD_9SU7DeSiQVrXA&s=09'} target='_blank' className="footer-icons">
                                    <FaXTwitter />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                </Container>
                <div className="bottom-footer">
                    Copyright 2004-2024 | Ayetec Solutions | All Rights Reserved.
                </div>
            </section>
        </div>

    )
}

export default Footer;